import { StateCreator } from 'zustand'
import { NP_METRIC_CONFIG } from '@netpurpose/types'

export type MetricConfigSlice = {
  selectedMetricConfigId: string | null
  setSelectedMetricConfigId: (id: string) => void
  selectedMetricConfigIdForExport: string
  setSelectedMetricConfigIdForExport: (id: string) => void
}

export const metricConfigSlice: StateCreator<MetricConfigSlice> = (set) => ({
  // Initialise to null to ensure requests aren't made until correct ID is established.
  selectedMetricConfigId: null,
  // Default is mainly for easier testing.
  selectedMetricConfigIdForExport: NP_METRIC_CONFIG,
  setSelectedMetricConfigId: (id) =>
    set(() => ({
      selectedMetricConfigId: id,
    })),
  setSelectedMetricConfigIdForExport: (id) =>
    set(() => ({
      selectedMetricConfigIdForExport: id,
    })),
})
